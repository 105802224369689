import React, { useEffect, useState } from 'react'
import { memo } from "react";
import { useAlert } from "react-alert";
import "./AllPages.css";
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryPercent, deleteFromPercentWatchlist, addtoPercentWatchlist, getDeliveryPercentWatchlist, getSearchDeliveryPercent } from '../Actions/allCategory';
import { ScheduleByShare } from '../Actions/BuySell';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import Select from 'react-select'
import { getBuySellInsight, getWatchSymbols } from '../Actions/Matrix';
import DatePicker from "react-datepicker";
// const Percent = [];
// const loading = false;




const ProfitLoss = () => {
    const [profitLossData, setProfitLossData] = useState([]);
    const [trackSymbols, setTrackSymbols] = useState([]);
    const [symbolList, setSymbolList] = useState([])
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [totalPL, setTotalPL] = useState(0);

    const alert = useAlert();

    //   const handleSaveTrackList = async() => {
    //     const data = trackSymbols.map(ele => ({symbol:ele.value}));
    //           const res = await updateTracklist({symols:data});
    //           if(res.success){
    //             alert.success("symbols added");
    //             setTrackSymbols([])
    //             // getTrackData();
    //           }
    //       }

    useEffect(() => {
        const getSymbols = async () => {
            const res = await getWatchSymbols();
            setLoading(false);
            if (res.success) {
                setSymbolList(res.data.map(ele => ({ label: ele, value: ele })));
            } else {
                alert.error("failed to fetch symbols");
                setSymbolList([]);
            }
        }
        getSymbols();
    }, [])

    const getProfitLoss = async () => {
        const data = {
            start_date: fromDate,
            end_date: toDate,
            symbols: trackSymbols.map(ele => ele.value)
        };
        setLoading(true);
        const res = await getBuySellInsight(data);
        setLoading(false);
        if (res.success) {
            setProfitLossData(res.data);
            const sumPL = res.data.reduce((acc, item) => acc + item.gainLoss, 0);
            setTotalPL(sumPL);
        } else {
            alert.error(res.message);
            setProfitLossData([]);
        }
    }
    if (loading) {
        return (
            <div className={"main-body"}>
                <h3 className='h3'>Profit & Loss</h3>
                <div className="Nodata">
                    <h5>.....Loading</h5>
                </div>
            </div>
        )
    }

    return (
        <div className={"main-body"}>
            <div className='topbar '>
                <div> <h3 className='h3'> Profit & Loss</h3></div>

                <div className='top-selct-symbol-cont'>
                    <Select
                        value={trackSymbols}
                        onChange={(val) => setTrackSymbols(val)}
                        options={symbolList}
                        isMulti
                        className='result-symbol-select'
                        closeMenuOnSelect={false}
                    />
                    <button
                        // onClick={handleSaveTrackList}
                        onClick={getProfitLoss}
                    >save</button>
                </div>

                <div className="top-datepicker-cont">
                    <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName="datamatrix-calender"
                        filterDate={date => date.getDay() !== 6 && date.getDay() !== 0}
                        // includeDates={Active}
                        // minDate={startDate}
                        maxDate={new Date()}
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                    />
                    <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName="datamatrix-calender"
                        filterDate={date => date.getDay() !== 6 && date.getDay() !== 0}
                        // includeDates={Active}
                        // minDate={startDate}
                        maxDate={new Date()}
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                    />
                </div>

            </div>
            <div className='per_watch_container'>
                <div>
                    <table className='percent-table'>
                        <thead>
                            <tr className='table-row'>

                                <th className="table-head">Symbol</th>
                                <th className="table-head" >Price</th>
                                <th className="table-head" >Selling Price</th>
                                <th className="table-head"  >Per</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                profitLossData?.length > 0 &&
                                profitLossData.map((ele, index) => {
                                    const { symbol, price, sellingPrice, gainLoss } = ele;
                                    return (
                                        <tr key={index} className='table-row'>
                                            <td className="table-data">{symbol}</td>
                                            <td className="table-data">{price}</td>
                                            <td className="table-data" >{sellingPrice}</td>
                                            <td className={gainLoss > 0 ? "table-data greenper" : "table-data redper"}>{gainLoss && gainLoss.toFixed(2)}</td>


                                        </tr>
                                    );
                                })
                            }
                           {profitLossData.length > 0 && <tr key={"45454"} className='table-row'>
                                <td className="table-data">-</td>
                                <td className="table-data">-</td>
                                <td className="table-data" >Total</td>
                                <td className={totalPL > 0 ? "table-data greenper" : "table-data redper"}>{totalPL && totalPL.toFixed(2)}</td>
                            </tr>}
                        </tbody>
                    </table>
                    {/* <Pagination page={page} count={Math.ceil(totalRecord / 10)} onChange={handlePagination} /> */}
                </div>


            </div>


        </div>
    )
}

export default memo(ProfitLoss)


