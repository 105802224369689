import axios from "axios"
const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});


export const addPattern = async (dataObj) => {
    try {
        const {data} = await axiosInstance.post(`/add_pattern`,dataObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}

export const getAIMatrixData = async (dataObj) => {
    try {
        const {data} = await axiosInstance.post(`/get_analyis`,dataObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}
export const getTrackWatch = async (payload) => {
    try {
        const {data} = await axiosInstance.post(`/get_track_patterns`,payload,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}

export const updateTracklist = async (payload) => {
    try {
        const {data} = await axiosInstance.post(`/add_track_symbol`,payload,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}

export const addPortfolio = async (dataObj) => {
    try {
        const {data} = await axiosInstance.post(`/add_portfolio`,dataObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}
export const getPortfolio = async () => {
    try {
        const {data} = await axiosInstance.get(`/get_portfolio`,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}
export const updatePortfolio = async (id,dataObj) => {
    try {
        const {data} = await axiosInstance.put(`/portfolio/${id}`,dataObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}

export const deletePortfolio = async (id) => {
    try {
        const {data} = await axiosInstance.delete(`/portfolio/${id}`,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}

export const getWatchSymbols = async () => {
    try {
        const {data} = await axiosInstance.get(`/watch_sybols`,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}

export const getBuySellInsight = async (obj) => {
    try {
        const {data} = await axiosInstance.post(`/profit-loss`,obj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}